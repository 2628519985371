
import { defineComponent } from "vue";
import { useStore } from "vuex";
import variables from "@/router/api";

export default defineComponent({
  name: "error-route",
  components: {},
  data() {
    return {
      role: "",
      route: ""
    };
  },
  created() {
    const store = useStore();
    const userType = store.getters.currentUser;

    // Policy document upload routes based on user roles
    if (userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLIENT_DASHBOARD}`;
    }
    if (userType.role == variables.BROKER_USER_ROLE) {
      this.route = `${variables.BROKER_DASHBOARD}`;
    }
    if (userType.role == variables.FINANCE_USER_ROLE) {
      this.route = `${variables.FINANCE_DASHBOARD}`;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      this.route = `${variables.ADMIN_DASHBOARD}`;
    } else {
      this.route = "/sign-in";
    }
  }
});
