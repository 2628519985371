import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `${_ctx.route}`,
      class: "btn btn-lg btn-primary fw-bolder cursor-pointer"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Go to dashboard ")
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}